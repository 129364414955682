import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import BetsCard from '@/components/business/bets/card';
import Text from '@/components/basic/text';
import theme from '@/style';
import {BasicObject} from '@/types';
import {goTo, toPriceStr} from '@/utils';
import dayjs from 'dayjs';
import React from 'react';
import {View, ViewStyle, StyleProp} from 'react-native';
import {useTranslation} from 'react-i18next';
import globalStore from '@/services/global.state';
import {toGame} from '../game-navigate';
import {getGameURL} from '@/pages/home/home.service';
import {toSports} from '@/utils';
import KeralaResult from './components/kerala-result/kerala-result';
import DigitResult from './components/digit/digit-result';
import ColorResult from './components/color/color-result';
import DiceResult from './components/dice/dice-result';
import Matka from './components/matka/matka';
import DetailCard from '@/components/business/bets/detail-card';
import ScratchResult from './components/scratch/scratch';
import SattaLottery from './components/satta-lottery/satta-lottery';
import {LotteryOrderTicket} from '../mix-lottery/mix-lottery-service';
import Price from '@/components/business/price';
import {gameNavigateVersion, homePage} from '@/config';
import {debounce} from '@/utils';

const BetsListItem = ({
  info,
  onCopy,
  canGoDetail = true,
  game = '',
  onShare = () => {},
  style = {},
  onRefresh = () => {},
  hasReward = false,
}: {
  info: BasicObject;
  onCopy?: (id: string) => void;
  canGoDetail?: boolean;
  game?: string;
  onShare?: () => void;
  onRefresh?: () => void;
  style?: StyleProp<ViewStyle>;
  hasReward?: boolean;
}) => {
  const {i18n} = useTranslation();
  const goPlay = () => {
    if (!globalStore.token) {
      goTo('Login', {backPage: homePage});
      return;
    }
    goTo('Scratch', {
      path: `my-scratch-list/detail/${info.orderId}`,
      hideInnerTitle: '1',
    });
  };

  const rightEle = (information: BasicObject = {}) => {
    if (information!.orderOverNum > 0) {
      return (
        <NativeTouchableOpacity
          onPress={goPlay}
          style={[
            theme.padding.lrs,
            theme.background.primary,
            theme.borderRadius.xs,
            theme.flex.center,
            theme.padding.tbxxs,
          ]}>
          <Text
            fontSize={10}
            fontFamily="fontInterBold"
            color={theme.basicColor.white}>
            ({information!.orderOverNum}/{information!.orderNumber})
          </Text>
          <Text fontFamily="fontInterBold" color={theme.basicColor.white}>
            {i18n.t('bets-page.label.playNow')}
          </Text>
        </NativeTouchableOpacity>
      );
    }
    return null;
  };

  const status = React.useMemo(() => {
    const {shareGameDto = {}} = info || {};
    const {gameCode} = shareGameDto;
    if (gameCode === 'kerala') {
      // Kerala
      if (info.bonusStatus === 0) {
        return info.wonAmount > 0 ? 1 : 0;
      }
      return info.bonusStatus;
    }
    if (
      gameCode === 'pick3' ||
      gameCode === '3Digit' ||
      gameCode === 'quick 3digit'
    ) {
      // 3 Digit
      if (info.wonCode && !info.wonCode.startsWith('*')) {
        // 已开奖
        return info.winAmount > 0 ? 1 : 0;
      }
      return 2;
    }
    if (gameCode === 'color') {
      // color
      if (info.openStatus === 1) {
        // 未开奖
        return 2;
      } else {
        return info.awardAmount > 0 ? 1 : 0;
      }
    }
    if (gameCode === 'matka') {
      if (info.openStatus === 1) {
        return 2;
      }
      return info.wonAmount > 0 ? 1 : 0;
    }
    if (gameCode === 'dice') {
      // dice
      if (info.openStatus === 1) {
        // 未开奖
        return 2;
      } else {
        return info.totalAwardAmount > 0 ? 1 : 0;
      }
    }
    if (gameCode === 'lottery official india') {
      if (!info.wonCode.every((v: string) => v.indexOf('-') === -1)) {
        // 未开奖
        return 2;
      } else {
        if (
          game === 'Quick State Lottery' ||
          info.ticketsLists.every(
            (v: LotteryOrderTicket) => v.ticketStatus === 2,
          )
        ) {
          return info.totalPrize > 0 ? 4 : 0;
        }
        return 3;
      }
    }
    if (info.orderStatus !== undefined && info.openStatus !== undefined) {
      // 1=中奖 0=未中奖 2=未使用
      // scratch
      if (info.openStatus === 2) {
        // 未开奖
        return 2;
      }
      return info.orderStatus;
    }
    return info.orderStatus;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  const hasShare = React.useMemo(() => {
    if (info?.gameType === 'quickRace') {
      return status === 1;
    }
    if (info?.shareGameDto?.gameCode === 'lottery official india') {
      return status === 4 || status === 2;
    }
    if (info?.shareGameDto?.gameCode === 'kerala') {
      return status === 1;
    }
    if (info?.shareGameDto?.gameCode === 'scratch') {
      return info?.orderWinAmount > 0;
    }
    return status === 1 || status === 2;
  }, [info, status]);

  const tag = React.useMemo(() => {
    const {shareGameDto = {}} = info || {};
    const {gameCode} = shareGameDto;
    if (gameCode === 'kerala') {
      // Kerala
      return info.issueNo;
    }
    if (gameCode === '3Digit' || gameCode === 'pick3') {
      return info.drawTime;
    }
    if (gameCode === 'quick 3digit') {
      return `${info.gameName}`;
    }
    if (gameCode === 'color' || gameCode === 'dice') {
      return `${info.cycle} Minute`;
    }
    if (gameCode === 'matka') {
      const time = dayjs(
        `${info.drawDate.split('-').reverse().join('-')} ${
          info.matkaResultVo.openTime
        }`,
      ).format('hh:mm A');
      return `OPEN ${time}`;
    }
    if (gameCode === 'lottery official india') {
      return game === 'Quick State Lottery'
        ? info.tabMin + ' Minute'
        : `No.${info.roundNo}`;
    }
    return '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  const tagTwo = React.useMemo(() => {
    const {shareGameDto = {}} = info || {};
    const {gameCode} = shareGameDto;
    if (gameCode === 'matka') {
      const time = dayjs(
        `${info.drawDate.split('-').reverse().join('-')} ${
          info.matkaResultVo.closeTime
        }`,
      ).format('hh:mm A');
      return `CLOSE ${time}`;
    }
    return '';
  }, [info]);

  const tip = React.useMemo(() => {
    const {shareGameDto = {}} = info || {};
    const {gameCode} = shareGameDto;
    if (gameCode === 'kerala') {
      // Kerala
      return info.gameName;
    }
    if (gameCode === 'pick3' || gameCode === '3Digit') {
      return `${info.gameName}-${info.drawTime}`;
    }
    if (gameCode === 'quick 3digit') {
      return `${info.drawTime}`;
    }
    if (gameCode === 'color' || gameCode === 'dice') {
      return dayjs(info.openTime).format('hh:mm A');
    }
    if (gameCode === 'matka') {
      return info.gameName;
    }
    if (gameCode === 'lottery official india') {
      return game === 'Quick State Lottery'
        ? dayjs(
            `${info.drawSec}`.length === 13
              ? info.drawSec
              : info.drawSec * 1000,
          ).format('hh:mm A')
        : `${info.lotteryName} No.${info.roundNo}`;
    }
    return '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  const DrawResult = React.useMemo(() => {
    const {shareGameDto = {}} = info || {};
    const {gameCode} = shareGameDto;
    if (gameCode === 'kerala') {
      return <KeralaResult list={info.userWonList} />;
    }
    if (
      gameCode === 'pick3' ||
      gameCode === '3Digit' ||
      gameCode === 'quick 3digit'
    ) {
      return <DigitResult wonCode={info.wonCode} codeLists={info.codeLists} />;
    }
    if (gameCode === 'color') {
      return (
        <ColorResult
          isOpen={info.openStatus !== 1}
          digitResult={info.digitResult}
          colorResult={info.colorResult}
          subsetList={info.subsetList}
        />
      );
    }
    if (gameCode === 'dice') {
      return (
        <DiceResult
          isOpen={info.openStatus !== 1}
          totalCount={info.totalCount}
          result={info.result}
          bigOrSmall={info.bigOrSmall}
          oddOrEven={info.oddOrEven}
          typeList={info.typeList}
        />
      );
    }
    if (gameCode === 'matka') {
      return (
        <Matka
          userDigits={info.userDigits}
          openNum={info?.matkaResultVo?.openNum}
          closeNum={info?.matkaResultVo?.closeNum}
        />
      );
    }
    if (gameCode === 'lottery official india') {
      return (
        <SattaLottery
          isQuick={game === 'Quick State Lottery'}
          wonCode={info.wonCode}
          ticketsLists={info.ticketsLists}
          awardAmount={info.totalPrize}
        />
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  const drawTime =
    info.drawDate ||
    info.gameDrawTime ||
    info.openTime ||
    info.drawTime ||
    info.drawSec;
  const id = info.orderGroup || info.orderId || info.issueNo || info.issNo;
  const reward =
    info.winAmount ||
    info.awardAmount ||
    info.wonAmount ||
    info.orderWinAmount ||
    info.totalAwardAmount ||
    info.totalPrize;

  const getSportURL = debounce((gametype: string) => {
    if (gametype === 'gr8_sport') {
      globalStore.openSports.next('/my-bets/open');
      return;
    }
    if (gametype === 'betby_sport') {
      goTo('BetBy');
      return;
    }
    getGameURL(gametype)
      .then((url: string) => {
        toSports(gametype.split('_')[0].toUpperCase() + ' Sports', url);
      })
      .catch(e => {
        const {log} = console;
        log('error', e);
      });
  });

  const goDetail = () => {
    if (!globalStore.token) {
      goTo('Login', {backPage: homePage});
      return;
    }
    if (game === 'Casino' || game === 'Live' || game === 'Quick Race') {
      toGame({
        source: info.source,
        name: info.gameName,
        gameUrl: info.gameUrl,
        id:
          gameNavigateVersion >= 1
            ? info.gameId
            : ['Slotegrator', 'WS168', 'CD', 'Evolution'].includes(info.source)
            ? info.gameId
            : info.orderId,
        tripartiteUniqueness: info.tripartiteUniqueness,
      });
    } else if (game === 'Sports') {
      getSportURL((info.source || '').toLowerCase().split(' ')[0] + '_sport');
    } else {
      goTo('BetsDetail', {
        info: JSON.stringify({...info, shareAward: hasReward ? 1 : 0}),
        game,
        onRefresh,
      });
    }
  };

  const time = React.useMemo(() => {
    const target = info.createTime || info.orderTime;
    if (target) {
      if (typeof target === 'string') {
        return target;
      }
      return dayjs(`${target}`.length === 13 ? target : target * 1000).format(
        'DD/MM/YYYY hh:mm A',
      );
    }
  }, [info]);

  const drawTimeStr = React.useMemo(() => {
    const {shareGameDto = {}} = info || {};
    const {gameCode} = shareGameDto;
    if (drawTime) {
      if (typeof drawTime === 'string') {
        if (gameCode === 'matka') {
          return dayjs(
            `${drawTime.split('-').reverse().join('-')} ${
              info.matkaResultVo.closeTime
            }`,
          ).format('DD/MM/YYYY hh:mm A');
        }
        return drawTime;
      }
      return dayjs(
        `${drawTime}`.length === 13 ? drawTime : drawTime * 1000,
      ).format('DD/MM/YYYY hh:mm A');
    }
  }, [drawTime, info]);

  const gameType = React.useMemo(() => {
    const {shareGameDto = {}} = info || {};
    const {gameCode} = shareGameDto;
    const normal = [
      'matka',
      'dice',
      'color',
      'quick 3digit',
      '3Digit',
      'kerala',
    ];
    // TODO到时候修改
    if (info.provider && info.provider.indexOf('Sports') > -1) {
      return 5;
    }
    if (gameCode === 'scratch') {
      return 2;
    }
    if (normal.includes(gameCode)) {
      return 1;
    }
    if (gameCode === 'lottery official india') {
      return 4;
    }
    return 3;
  }, [info]);

  const name = React.useMemo(() => {
    if (game === 'Quick 3D') {
      return `3 Digit-${info.gameName}`;
    }
    if (game === 'State Lottery') {
      return `${info.lotteryName} No.${info.roundNo}`;
    }
    if (game === 'Quick State Lottery') {
      return `${info.lotteryName}-${info.tabMin}min`;
    }
    return info.gameName || info.issueNo;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  return gameType === 1 || gameType === 4 ? (
    <BetsCard
      hasAward={hasReward}
      style={style}
      goDetail={goDetail}
      canGoDetail={canGoDetail}
      onCopy={() => onCopy && onCopy(id)}
      onShare={onShare}
      tag={tag}
      tagTwo={tagTwo}
      statusTip={tip}
      payment={
        info.totalAmount ||
        info.amount ||
        (info.orderAmount && info.orderAmount * (info.orderNumber || 1))
      }
      result={DrawResult}
      status={status}
      betTime={time}
      drawTime={drawTimeStr}
      id={id}
      reward={reward}
      hasResult={gameType === 1}
      title={name}
      hasShare={hasShare}
      cover={info.gameIconUrl || info.gameIcon || info.icon}
    />
  ) : (
    <View
      style={[
        theme.background.white,
        theme.margin.topl,
        theme.padding.l,
        theme.borderRadius.m,
      ]}>
      <DetailCard
        canGoDetail
        onPress={goDetail}
        hasResult={gameType === 3 || gameType === 5}
        awardNumber={reward}
        result={
          <View
            style={[
              theme.flex.row,
              theme.flex.centerByCol,
              {columnGap: theme.paddingSize.xxs},
            ]}>
            <Text fontWeight="500">
              {status === 2
                ? i18n.t('bets-detail.label.noDraw')
                : reward > 0
                ? i18n.t('bets-detail.label.won')
                : i18n.t('bets-detail.label.noWin')}
            </Text>
            {gameType === 5 && status !== 2 && (
              <Price
                textProps={{
                  primary: true,
                }}
                price={reward || 0}
              />
            )}
          </View>
        }
        id={id}
        amount={
          info.totalAmount ||
          info.amount ||
          (info.orderAmount && info.orderAmount * (info.orderNumber || 1))
        }
        onCopy={() => onCopy?.(id)}
        betTime={time}
        status={status}
        hasResultTip={gameType !== 5}
        betResult={
          gameType === 2 && <ScratchResult orderList={info.orderList} />
        }
        rightEle={gameType === 2 ? rightEle(info) : null}
        tip={
          gameType === 3 ? (
            info.source
          ) : gameType === 5 ? (
            <View style={[theme.margin.topxxs]}>
              <Text>{info.provider}</Text>
            </View>
          ) : (
            <Text
              size="medium"
              fontFamily="fontDinBold"
              color={theme.basicColor.dark}>
              {toPriceStr(info.orderPrice)}x{info.orderNumber}
            </Text>
          )
        }
        name={info.gameName || info.issueNo}
        gameLogo={info.gameIconUrl || info.gameIcon || info.icon}
        hasShare={hasShare}
        onShare={onShare}
        hasAward={hasReward}
      />
    </View>
  );
};

export default BetsListItem;
